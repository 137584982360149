import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Boxes = () => {
  const navigate = useNavigate();
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );
  console.log(deshboard);
  // const totalUsers = useSelector(state => state.dashboard.totalUsers || "");
  // const totalFaqs = useSelector(state => state.dashboard.totalFaqs || "");
  // const TotalJobsAdd = useSelector(state => state.dashboard.TotalJobs || "");
  // const totalJobApp = useSelector(state => state.jobAppliction.totalJobApp || 0);
  // console.log(totalJobApp);
  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Total User</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText'>{deshboard?.total_user || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
        //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Total Contact</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText color-risk'>{deshboard?.total_contact || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Category
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText'>{deshboard?.quiz_category || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Total Quiz
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText color-risk'>{deshboard?.total_quiz || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/question")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Question
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText'>{deshboard?.total_question || 0}</div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Boxes;
