import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input, Select, message } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { BookOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";


function Subjectcategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const subject_category_list = useSelector((state) =>
    state?.allapi?.subject_category_list ? state?.allapi?.subject_category_list : {}
  );


  console.log(subject_category_list);

  const listdata = subject_category_list?.data ? subject_category_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.delete_subject_category({id: e, page: page }));
    dispatch(allapiAction.subject_category_list({page:page }));

    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };


  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpensub, setIsModalOpensub] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };

  
  const [subDetail, setsubDetail] = useState({});
console.log(subDetail);
  const hendleTochangesub = (e) => {
    const { name, value } = e.target;
    setsubDetail({ ...cateDetail, [name]: value });
  };
  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);
    setIsModalOpen(true);
  };
  const showModalsub = (e) => {
    console.log(e);
    setsubDetail(e);
    console.log(e)
    setIsModalOpensub(true);
    dispatch(allapiAction.subject_list(1));
    dispatch(allapiAction.subjects_by_category({category_id:e}));
    
  };
  const handleOk = () => {
    dispatch(
      allapiAction.update_subject_category({
        id: cateDetail?.id,
        name: cateDetail?.name,
        short_name:cateDetail?.short_name,
        page: page,
      })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    setIsModalOpen(false);
    
    dispatch(allapiAction.subject_category_list(page));
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpensub(false);
  };
  
  const [cateDetailAdd, setcateDetailAdd] = useState({});

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);
    setAddModalOpen(true);
  };
  const handleOkAdd = () => {
    if (!cateDetailAdd?.name) {
      toast.error("please enter name");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("name", cateDetailAdd?.name);
      data.append("short_name", cateDetailAdd?.short_name);

      dispatch(allapiAction.create_subject_category(data));

      setcateDetailAdd({ name: "" , short_name: "" });
      dispatch(allapiAction.subject_category_list({page:page }));
      // dispatch(adminAction.getRoleList('dhsg'))
      setAddModalOpen(false);
      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
        title: "Short name",
      dataIndex: "short_name",
      key: "short_name",
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
           <Tooltip title="Subject">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModalsub(item?.id);
                }}
              >
                <BookOutlined className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.subject_category_list(1));

    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.subject_category_listSearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.subject_category_list(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.subject_category_listSearch({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.subject_category_list(e));

      return () => {};
    }
  };
  const subjects_by_category = useSelector((state) =>
  state?.allapi?.subjects_by_category ? state?.allapi?.subjects_by_category : {}
);
const deleteSubjectFromCategory = useSelector((state) =>
state?.allapi?.deleteSubjectFromCategory ? state?.allapi?.deleteSubjectFromCategory : {}
);


console.log(subjects_by_category)
  const subject_list = useSelector((state) =>
  state?.allapi?.subject_list ? state?.allapi?.subject_list : {}
);

  console.log(subject_list);

  const sublistdata = subject_list?.data ? subject_list?.data : [];
  // useEffect(() => {
  //   dispatch(allapiAction.subject_list(1));

  //   return () => {};
  // }, []);
 

  const options = sublistdata.map((user) => ({
    label: user.name,
    value: user.id,
    email: user.email, // Add the email property if it exists in your user object
    // Add other properties as needed
  }));


   const add_subjects = useSelector((state) =>
   state?.allapi?.add_subjects ?  state?.allapi?.add_subjects : {}
   );
   
   console.log(add_subjects)
  const [selectedSubjects, setSelectedSubjects] = useState([]);


  console.log(selectedSubjects);
  const handleChangesub = (selectedValues) => {
    setSelectedSubjects([...new Set([...selectedSubjects, ...selectedValues])]);
  };

  console.log(subDetail);

  const handleOkAddSubject = async (e) => {
    e?.preventDefault();
    console.log('submitted');
  
    let data = new FormData();
    data.append("category_id", subDetail);
  
    // Iterate over selectedSubjects array and append each subject to FormData
    selectedSubjects.forEach((subject, index) => {
      data.append(`subjects[${index}]`, subject);
    });
  
    // redux
    dispatch(allapiAction.add_subjects(data));
  
    console.log(data);
  }

  const handleDeletesubjectfromcategory = async(id)=>{
    
      await dispatch(allapiAction.deleteSubjectFromCategory());
      setShowDelete(false);
     
  }

  
  return (
    <div>
      {/* subject */}
      <div className="modalselectbtn">
      <Modal
        title="Add Subject to Course"
        open={isModalOpensub}
        onOk={handleOkAddSubject}
        onCancel={handleCancel}
      >
  <Col lg={24} md={24} sm={24} xs={24}>
  <Select
          id="subjects"
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Select Subject"
          defaultValue={options.length > 0 ? [options[0].value] : []}
          value={selectedSubjects}
          onChange={handleChangesub}
          optionLabelProp="label"
          options={options}
          optionRender={(option) => (
            <Space>
              {option.label}
            </Space>
          )}
        />
        </Col>
        <div className="subjects">
          <p>Slelected Subjects</p>
          <ul>
  {subjects_by_category?.subjects?.map((subject, index) => (
    <li key={index}>{subject.subject.name}<span
                className="deletecomments"
                onClick={() => handleDeletesubjectfromcategory(subjects_by_category?.subjects?.id)}
                style={{ color: "green" }}
              >
                <img src="images/remove.png" style={{ width: "13px" }} alt="delete" />
              </span></li>
    
  ))}
</ul>


        </div>
      </Modal>
      </div>
  {/* update */}
      <Modal
        title="Update Course Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
           <input
            autoComplete="new-off"
            className="form-control"
            placeholder="Short name"
            name="short_name"
            value={cateDetail?.short_name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
      </Modal>

      {/* create */}
      <Modal
        title="Create Course Category"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="Short name"
            name="short_name"
            value={cateDetailAdd?.short_name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Course"}
            subHeading={`Total ${subject_category_list?.total || 0} Course`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total:subject_category_list?.total? subject_category_list?.total:0
          }}
        />
      </div>
    </div>
  );
}

export default Subjectcategory;

