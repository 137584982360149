import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { allapiAction } from '../../Redux/common/action';
import { Button, Card, Input, Modal } from 'antd';
import { useState } from 'react';

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );
  const admin_update = useSelector((state) =>
    state?.allapi?.admin_update ? state?.allapi?.admin_update : {}
  );

 

  useEffect(() => {
    dispatch(allapiAction.profile());
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for showing/hiding password
  const [showPasswordHint, setShowPasswordHint] = useState(false); // State for showing/hiding password hint
  const [userUpdate, setUserUpdate] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToForm = (e) => {
    const { name, value } = e?.target;
    setUserUpdate((prevUserUpdate) => ({ ...prevUserUpdate, [name]: value }));
  };

  const handleOk = (e) => {
    e.preventDefault();

    let data = new FormData();

    data.append('email', profile?.email);
    data.append('password', userUpdate?.password); // Assuming this is the existing password
    data.append('new_password', userUpdate?.new_password);

    dispatch(allapiAction.admin_update(data));
    setIsModalOpen(false);
  };

  const getImageUrl = (path) => {
    return `${URL.API_BASE_URL}${path}`;
  };

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle the case when the date is not available

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    };

    const formattedDate = new Date(dateString).toLocaleString('en-US', options);
    return formattedDate;
  };

  return (
    <div className="adminprofile">
      <Card style={{}}>
        <div className="row">
          <div className="col-4 profileimgadmin">
            <img src={getImageUrl(profile?.profile_image)} alt="Profile" />
          </div>
          <div className="col-8">
            <p>{profile?.name}</p>
            <p>{profile?.email}</p>
            <p>Updated : {formatDate(profile?.updated_at)}</p>
            <p>Created : {formatDate(profile?.created_at)}</p>
            <p>Contact saved : {profile?.contact_saved ? 'Yes' : 'No'}</p>
            <p>Active : {profile?.active ? 'Yes' : 'No'}</p>

            <>
              <Button type="primary1" onClick={showModal}>
                Change Password
              </Button>
              <Modal
                title="Change Password"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="passchange"
              >
                <Input
                  type="email"
                  name="email"
                  value={profile?.email}
                  onChange={(e) => hendleToForm(e)}
                  readOnly
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={profile?.password}
                  onChange={(e) => hendleToForm(e)}
                  placeholder="Old Password"
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="new_password"
                  value={profile?.new_password}
                  onChange={(e) => hendleToForm(e)}
                  placeholder="New Password"
                />
                <label>
                  Show Password
                  <input
                    type="checkbox"
                    checked={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                  />
                </label>
                {profile?.password && (
                  <p>
                    Password Hint: {profile?.password.slice(-2)} {/* Last two digits of old password */}
                  </p>
                )}
              </Modal>
            </>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Profile;
