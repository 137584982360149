import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
// import { LogoutOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
// import { DeleteToken } from '../../utils';
import Profile from '../../Login/Profile';
import { useState } from 'react';

const TopBar = ({ collapsed, setCollapsed }) => {
    const navigate = useNavigate();
    // const handleLogout = () => {
    //     DeleteToken();
    //     navigate("/login", { replace: true });
    // }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleOk = () => {  
        setIsModalOpen(false);
      }  
    return (
        <div className='flex justify-content-between align-items-center'>
            <div>{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
            })}</div>
            <div className='mr-20 profiletopset'>
                {/* <span className='logout-btn' onClick={() => handleProfile()}>Profile<LogoutOutlined /></span> */}
                <>
              <Button type="primary1" onClick={showModal}>
                Profile
              </Button>
              <Modal style={{width:800}}
                title="Profile"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="passchange"
              >
              <Profile/>
              </Modal>
              </>
            </div>
        </div >
    )
}

export default TopBar