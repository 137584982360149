import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";

import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";

function Question() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const question_list = useSelector((state) =>
    state?.allapi?.question_list ? state?.allapi?.question_list : {}
  );

  console.log(question_list);

  const listdata = question_list?.data ? question_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const [seleceteManger, setseleceteManger] = useState({});
  const [seleceteCateUpdate, setseleceteCateUpdate] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };

  const showModal = (e) => {
    console.log(e);


    const ansselect = e?.answer == e?.option1 ? "option1": e?.answer == e?.option2 ?"option2" :e?.answer == e?.option3?"option3":e?.answer == e?.option4?"option4":""
    
    console.log(ansselect);
    e.answer = ansselect
    setcateDetail(e);

    if (e?.quiz !== null) {
      const seriresDetatail = e?.quiz;
      seriresDetatail.value = e?.quiz_id;
      seriresDetatail.label = e?.quiz?.name;

      setseleceteCateUpdate(seriresDetatail);
    }
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (!seleceteManger?.id) {

      const getAns =
      cateDetail?.answer == "option1"
        ? cateDetail?.option1
        : cateDetail?.answer == "option2"
        ? cateDetail?.option2
        : cateDetail?.answer == "option3"
        ? cateDetail?.option3
        : cateDetail?.option4;

      dispatch(
        allapiAction.update_question({
          //   quiz_id: seleceteManger?.id,
          q_id: seleceteCateUpdate?.id,
          name: cateDetail?.name,
          page: page,
          quiz_id: 0,
          type: "all",
          question_id: cateDetail?.id,
          question: cateDetail?.question,
          option1: cateDetail?.option1,
          option2: cateDetail?.option2,
          option3: cateDetail?.option3,
          option4: cateDetail?.option4,
          answer: getAns,
        })
      );
    } else {
      const getAns =
      cateDetail?.answer == "option1"
        ? cateDetail?.option1
        : cateDetail?.answer == "option2"
        ? cateDetail?.option2
        : cateDetail?.answer == "option3"
        ? cateDetail?.option3
        : cateDetail?.option4;


      dispatch(
        allapiAction.update_question({
          quiz_id: seleceteManger?.id,
          q_id: seleceteCateUpdate?.id,
          name: cateDetail?.name,
          page: page,
          //   quiz_id: 0,
          type: "All",
          question_id: cateDetail?.id,
          question: cateDetail?.question,
          option1: cateDetail?.option1,
          option2: cateDetail?.option2,
          option3: cateDetail?.option3,
          option4: cateDetail?.option4,
          answer: getAns,
        })
      );
    }

    // dispatch(adminAction.getRoleList('dhsg'))
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);

    setAddModalOpen(true);
  };

  //   console.log(cateDetailAdd);

  const [seleceteCateCreate, setseleceteCateCreate] = useState({});

  const handleOkAdd = () => {
    if (!cateDetailAdd?.question) {
      toast.error("please enter question");
    } else if (!seleceteCateCreate?.id) {
      toast.error("please select quiz");
    } else if (!cateDetailAdd?.option1) {
      toast.error("please enter option1");
    } else if (!cateDetailAdd?.option2) {
      toast.error("please enter option2");
    } else if (!cateDetailAdd?.option3) {
      toast.error("please enter option3");
    } else if (!cateDetailAdd?.option4) {
      toast.error("please enter option4");
    } else if (!cateDetailAdd?.answer) {
      toast.error("please enter answer");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("question_id", cateDetail?.id,);
      data.append("question", cateDetailAdd?.question);
      data.append("quiz_id", seleceteCateCreate?.id);
      data.append("option1", cateDetailAdd?.option1);
      data.append("option2", cateDetailAdd?.option2);
      data.append("option3", cateDetailAdd?.option3);
      data.append("option4", cateDetailAdd?.option4);

      const getAns =
        cateDetailAdd?.answer == "option1"
          ? cateDetailAdd?.option1
          : cateDetailAdd?.answer == "option2"
          ? cateDetailAdd?.option2
          : cateDetailAdd?.answer == "option3"
          ? cateDetailAdd?.option3
          : cateDetailAdd?.option4;

      data.append("answer", getAns);

      dispatch(allapiAction.create_quiz_question(data));

      setcateDetailAdd({
        question_id: "",
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        answer: "",
      });
      // dispatch(adminAction.getRoleList('dhsg'))
      setAddModalOpen(false);
      setseleceteCateCreate(null);
      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Question id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Option1",
      dataIndex: "option1",
      key: "option1",
    },
    {
      title: "Option 2",
      dataIndex: "option2",
      key: "option2",
    },
    {
      title: "Option 3",
      dataIndex: "option3",
      key: "option3",
    },
    {
      title: "Option 4",
      dataIndex: "option4",
      key: "option4",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
    },
    {
      title: "Quiz",
      dataIndex: "quiz",
      key: "quiz",
      render: (name, item) => <>{item?.quiz?.name}</>,
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    setShowDelete(false);

    if (!seleceteManger?.id) {
      dispatch(
        allapiAction.delete_question({
          question_id: e,
          page: page,
          quiz_id: 0,
          type: "all",
        })
      );
    } else {
      dispatch(
        allapiAction.delete_question({
          question_id: e,
          page: page,
          quiz_id: seleceteManger?.id,
          type: "All",
        })
      );
    }

    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  useEffect(() => {
    dispatch(allapiAction.questionsLlist({ page: 1, quiz_id: 0, type: "all" }));

    return () => {};
  }, []);

  const hendleTofilter = () => {
    dispatch(
      allapiAction.questionsLlist({
        page: 1,
        quiz_id: seleceteManger?.id,
        type: "All",
      })
    );

    return () => {};
  };
  const handleFilterReset = () => {
    dispatch(
      allapiAction.questionsLlist({
        page: 1,
        quiz_id: 0,
        type: "all",
      })
    );
    setseleceteManger(null);
    return () => {};
  };

  const loadOptionsgetManager = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    console.log(search);
    console.log(page);

    const response = await fetch(
      URL.API_BASE_URL + `/api/all_quiz?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.data?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadOptionsgetQuizCreate = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    const response = await fetch(
      URL.API_BASE_URL + `/api/all_quiz?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.data?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadOptionsgetQuizUpdate = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    const response = await fetch(
      URL.API_BASE_URL + `/api/all_quiz?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.data?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.questionsLlistSearch({
            page: 1,
            quiz_id: seleceteManger?.id,
            type: "All",
            search: e,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.questionsLlistSearch({
            page: 1,
            quiz_id: 0,
            type: "all",
            search: e,
          })
        );
        // setseleceteManger(null);
        return () => {};
      }

      // dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      // return () => {};
    } else {
      // questionsLlistSearch
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.questionsLlist({
            page: 1,
            quiz_id: seleceteManger?.id,
            type: "All",
            // search: search,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.questionsLlist({
            page: 1,
            quiz_id: 0,
            type: "all",
          })
        );
        // setseleceteManger(null);
        return () => {};
      }

      // dispatch(allapiAction.allcategory(e));

      // return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.questionsLlistSearch({
            page: e,
            quiz_id: seleceteManger?.id,
            type: "All",
            search: search,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.questionsLlistSearch({
            page: e,
            quiz_id: 0,
            type: "all",
            search: search,
          })
        );

        return () => {};
      }
    } else {
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.questionsLlist({
            page: e,
            quiz_id: seleceteManger?.id,
            type: "All",
            // search: search,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.questionsLlist({
            page: e,
            quiz_id: 0,
            type: "all",
          })
        );
        // setseleceteManger(null);
        return () => {};
      }
    }
  };

  return (
    <div>
      {/* update */}
      <Modal
        title="Update Question"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24} className=" mt-15">
          <label>Select quiz</label>
          <AsyncPaginate
            // key={managerKey}
            placeholder="select quiz"
            value={seleceteCateUpdate}
            loadOptions={loadOptionsgetQuizUpdate}
            onChange={setseleceteCateUpdate}
            additional={{
              page: 1,
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>Question</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="question"
            name="question"
            value={cateDetail?.question}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 1</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 1"
            name="option1"
            value={cateDetail?.option1}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 2</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 2"
            name="option2"
            value={cateDetail?.option2}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 3</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 3"
            name="option3"
            value={cateDetail?.option3}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 4</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 4"
            name="option4"
            value={cateDetail?.option4}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>answer</label>
          {/* <input
            autoComplete="new-off"
            className="form-control"
            placeholder="answer"
            name="answer"
            value={cateDetail?.answer}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          /> */}


          <select
            name="answer"
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
            className="form-control"
            value={cateDetail?.answer}
          >
            <option value={""}>select answer</option>
            <option value={"option1"}> option 1</option>
            <option value={"option2"}> option 2</option>
            <option value={"option3"}> option 3</option>
            <option value={"option4"}> option 4</option>
          </select>
        </Col>
      </Modal>
      {/* create */}

      <Modal
        title="Create Question"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24} className=" mt-15">
          <label>Select quiz</label>
          <AsyncPaginate
            // key={managerKey}
            placeholder="select quiz"
            value={seleceteCateCreate}
            loadOptions={loadOptionsgetQuizCreate}
            onChange={setseleceteCateCreate}
            additional={{
              page: 1,
            }}
          />
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <label>Name</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col> */}
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>Question</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="question"
            name="question"
            value={cateDetailAdd?.question}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 1</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 1"
            name="option1"
            value={cateDetailAdd?.option1}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 2</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 2"
            name="option2"
            value={cateDetailAdd?.option2}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 3</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 3"
            name="option3"
            value={cateDetailAdd?.option3}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>option 4</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="option 4"
            name="option4"
            value={cateDetailAdd?.option4}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>answer</label>
          {/* <input
            autoComplete="new-off"
            className="form-control"
            placeholder="answer"
            name="answer"
            value={cateDetailAdd?.answer}
            // onChange={(e) => {
            //   hendleTochangeCategoryAdd(e);
            // }}
          /> */}

          <select
            name="answer"
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
            className="form-control"
            value={cateDetailAdd?.answer}
          >
            <option value={""}>select answer</option>
            <option value={"option1"}> option 1</option>
            <option value={"option2"}> option 2</option>
            <option value={"option3"}> option 3</option>
            <option value={"option4"}> option 4</option>
          </select>
        </Col>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Question"}
            subHeading={`Total ${question_list?.total || 0} Question`}
          />
        </div>

        {/* <div> */}

        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        // onChange={(e) => handleChange("TestCode", e.target.value)}
                        placeholder='Test Name/Test Code'
                        // value={values?.TestCode}
                    /> */}
          <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          />
        </Col>
        {/* </div> */}

        <div className="pl-5 ml-5">
          <button className="filter-button" onClick={() => hendleTofilter()}>
            Filter
          </button>
          {/* <Tooltip title="Add">
            <span className="jop_btn" onClick={() => hendleTofilter()}>
              Filter
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip> */}
        </div>
        <div className="pl-5 ml-5">
          <button
            className="clear-button clear-button1"
            onClick={() => handleFilterReset()}
          >
            Reset
          </button>

          {/* <Tooltip title="Add">
            <span className="jop_btn" onClick={() => hendleTofilter()}>
              Filter
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip> */}
        </div>

        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>
        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: question_list?.total ? question_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Question;
