


import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled, LikeOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";

import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";
import { Card, Row } from 'antd';

function Post() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [showDelelt, setShowDelete] = useState(false);
  const [seleceteManger, setseleceteManger] = useState({});

  const post_list = useSelector((state) =>
    state?.allapi?.allpost ? state?.allapi?.allpost : {}
  );

  const comment_list = useSelector((state) =>
  state?.allapi?.all_comments ? state?.allapi?.all_comments : {}
);
const comments = comment_list?.data ? comment_list?.data : [];
console.log(comment_list)
  console.log(post_list);

  const listdata = post_list?.data ? post_list?.data : [];
  const post_id = listdata.map(item => item?.id);

  const delete_post = useSelector((state) =>
  state?.allapi?.delete_post ? state?.allapi?.delete_post : {}
);

const delete_comment = useSelector((state) =>
state?.allapi?.delete_comment ? state?.allapi?.delete_comment : {}
);

  console.log(post_id);
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];
const { confirm } = Modal;

const showDeleteConfirm = (post_id) => {
  confirm({
    title: "Are you sure to delete?",
    icon: <ExclamationCircleFilled />,
    content: "Some descriptions",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      handleDelete(post_id);
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};

const handleDelete = async (post_id) => {
  const formdata = new FormData();
  formdata.append("post_id", post_id);

  try {
    await dispatch(allapiAction.delete_post(formdata, {page: page}));
    setShowDelete(false);
    toast.success("Post deleted successfully");
    // dispatch(allapiAction.delete_post({id: e, page: page }));
    dispatch(allapiAction.allpost({page:page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  } catch (error) {
    // Handle deletion failure
    toast.error("Error deleting post");
  }

};



useEffect(() => {
  dispatch(allapiAction.allpost({ page: 1, id: 0, type: "all" }));
  // Cleanup function
  return () => {};
}, []);




const getImageUrl = (path) => {
  return `${URL.API_BASE_URL}${path}`;
};
// const handleOk = (post_id) => {
//   // Toggle chatOpen for the clicked post
// dispatch(allapiAction.all_comments({ page: 1, post_id: post_id, type: "all" }));

//   // setOpenChatPostId((prevId) => (prevId === post_id ? null : post_id));
// };
const handleDeleteComment = async(comment_id, id)=>{
  const formdata = new FormData();
  formdata.append("post_id", id);
  formdata.append("comment_id", comment_id);
  try {
    await dispatch(allapiAction.delete_comment(formdata));
    setShowDelete(false);
    // toast.success("Comment deleted successfully");
    // dispatch(allapiAction.delete_post({id: e, page: page }));
    // dispatch(allapiAction.all_comments({page:page}));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  } catch (error) {
    // Handle deletion failure
    // toast.error("Error deleting comment");
  }
}
const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedPostId, setSelectedPostId] = useState(null);
const[commentsdata,setcommentsdata]=useState(null);
console.log(selectedPostId)



const showModal = (post_id) => {
  if (comment_list !== null) {
    dispatch(allapiAction.all_comments({ page: 1, post_id: post_id, type: "all" }));
    setSelectedPostId(post_id);
    setIsModalOpen(true);
  } else {
    setIsModalOpen(false);
  }
};



const handleOk = async(post_id) => {
 
  setIsModalOpen(false);
};

const handleCancel = () => {
  setIsModalOpen(false);
  // dispatch(apiResetAction());
};


const formattedTime = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  
  // Determine AM or PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Format hours and minutes with leading zeros if needed
  const formattedHoursWithZero = formattedHours < 10 ? `0${formattedHours}` : formattedHours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Create a string in the "hh:mm AM/PM" format
  return `${formattedHoursWithZero}:${formattedMinutes} ${ampm}`;
};


const groupCommentsByDay = (comments) => {
  const groupedComments = {};

  comments.forEach((comment) => {
    // Get the date in "YYYY-MM-DD" format
    const date = new Date(comment.created_at).toISOString().split('T')[0];

    if (!groupedComments[date]) {
      groupedComments[date] = [];
    }

    groupedComments[date].push(comment);
  });

  return groupedComments;
};
const groupedComments = groupCommentsByDay(comment_list);

return (
  <div>
  <div className="cardstudentdetail">
      <Row gutter={16}>
      {listdata.map((item) => (
            <Col key={item.post_id} span={6}>

<Card
title={
  <div>
    {item?.user?.profile_image ? (
      <img
        src={getImageUrl(item?.user?.profile_image)}
        alt=""
        style={{ width: "15px" ,marginRight:"5px"}}
      />
    ) : (
      <img src="images/user.png" alt="Default User" style={{ width: "15px" , marginRight:"5px" }} />
    )}
    {item?.user?.name}{" "}
    {item?.user?.active ? (
      <span className="onlineicon" style={{ color: 'green' }}>
        <img src="images/online.png" alt="Online" style={{ width: "10px" }} />
      </span>
    ) : (
      <span style={{ color: 'red' }}>.</span>
    )}
  </div>
}

bordered={false}
style={{ padding: "0px!important" }}
>


<p><span className="titleanthead"> {item.title}</span></p>
<img src={getImageUrl(item?.image)} alt="" style={{width:"100%"}} />
<Row gutter={16} className="antborder">
  <Col span={8} className="antborder3">
    <img src="images/like.png" style={{ width: "15px" }} />
    {item?.likes_count}
  </Col>


<Col span={8} className="antborder2">
<>
      <Button type="primary" onClick={()=>{showModal(item?.id)}}>
        <img src="images/comment.png" style={{ width: "15px" }} />
      {item?.comments_count}
      </Button>
      <Modal  title="Comments"
                visible={isModalOpen && selectedPostId === item.id}
                onOk={handleOk}
                onCancel={handleCancel}
              >
             {Object.entries(groupedComments).map(([day, comments]) => (
      <div key={day}>
        <span className="dategroup"><p>{day}</p></span>
        {comments.map((comment) => (
          <div key={comment.id} className="row commentsection">
            {/* Render your comment content here */}
            <div className="col-6">
            <span className="profileimg">
  {comment.profile_image ? (
    <img
      src={getImageUrl(comment.profile_image)}
      alt=""
      style={{ width: "20px", borderRadius: "2em" , marginBottom:"5px"}}
    />
  ) : (
    <img
      src="images/user.png"
      alt="Default User"
      style={{ width: "20px", borderRadius: "2em" , marginBottom:"5px" }}
    />
  )}
</span>

              <p>{comment.comment}</p>
              <span className="timesend">{formattedTime(comment.created_at)}</span>
            </div>
            <div className="col-6 deletecomments">
              <span
                className="deletecomments"
                onClick={() => handleDeleteComment(comment.id, item.id)}
                style={{ color: "green" }}
              >
                <img src="images/remove.png" style={{ width: "13px" }} alt="delete" />
              </span>
            </div>
          </div>
        ))}
      </div>
    ))}
    
      </Modal>
    </>
  </Col>


    <Col span={8} className="antborder1">
      <span
        onClick={() => showDeleteConfirm(item.id)}
        style={{ color: "green" }}
      >
        <img src="images/delete.png" style={{ width: "13px" }} />
      </span>
    </Col>


    </Row>

  </Card>
</Col>
))}
</Row>
</div>

</div>
);
}

export default Post;
