import "firebase/firestore";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React from 'react'
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../Firebase";
          
function Batchmembers() {


  const [user, setUsers] = useState({});
  // const route = useRouter();
  const { id } = useParams();
  console.log(user);

  useEffect(() => {
  
    hendletogetprofile();
  }, []);

  const hendletogetprofile = async () => {
    const userDeatail =  typeof window !== "undefined" ? localStorage.getItem("access_user") : "";

    
    const q = query(
      collection(db, `Batch Chat/${id}/Studentdetail`),
      where("uid", "==", userDeatail)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());

      setUsers(doc.data())
    });
  };

  // useEffect(() => {
  //     getDataFire();
  //   }, []);
  //   const getDataFire = async () => {
  //     const querySnapshot = await getDocs(collection(db, "userDatas"));
  //     const usersDataArray = querySnapshot.docs.map(doc => doc.data());
  //     console.log(usersDataArray);
  //     setUsers(usersDataArray)
  //   };

  






  return (
    <div>

          <p>name:{user.StudentName}</p>
        
<div>
       
           </div>
           </div>  
            )
          }
          
          export default Batchmembers