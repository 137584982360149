import React, { useEffect, useRef, useState } from "react";
import { getUserDetail } from "../../Utils/Auth/Token";

import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  doc,
  where,
  getDocs,
  setDoc,
  deleteDoc,
  deleteField,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { db } from "../Firebase";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { useLocation } from "react-router-dom";
import Groupinfo from "./Batchinfo";


function Chat() {
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [userid, setUserid] = useState("");
    // const [useriddoc, setUseriddoc] = useState("");
    // const [onlineStatus, setOnlineStatus] = useState("offline"); // Add onlineStatus state
    const [file, setFile] = useState(null);
    const [messageType, setMessageType] = useState("text"); // Added messageType state
    const ourid = getUserDetail();
    const chatId = userid > ourid ? userid + "-" + ourid : ourid + "-" + userid;
    const firstuserId = userid > ourid ? userid   : ourid ;
    const seconduserId = userid > ourid ? ourid   : userid ;

  const [fileUploadTask, setFileUploadTask] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;
 const dispatch =useDispatch();

 const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );


  const [pages, setPage] = useState(1);


//   console.log(typeof page);
//   console.log(typeof pages);



  const listdata = users_list?.data ? users_list?.data : [];



  useEffect(() => {

    if (search) {
      dispatch(allapiAction.getuserListSearch({ page: page, search: search }));
    } else {
      dispatch(allapiAction.getuserList(page)); 
    }
  

    return () => {};
  }, [location]);


// useEffect

  const handleFileChange = 
  (event) => {
    const selectedFile = event.target.files; // Get the selected files
    const imageArray = [];
    
    // Loop through the selected files and add them to the array
    for (let i = 0; i < selectedFile.length; i++) {
      imageArray.push(URL.createObjectURL(selectedFile[i])); // Convert files to URLs
    }

    setFile(imageArray); // Update the state with selected image URLs
  };



  const handleSendMessage = async () => {

    console.log("sdfhgsdg");
    if (message.trim() === "" && !file) return;
 
    try {
      if (messageType === "text") {
        // Send a text message
        await addDoc(collection(db, `Batch Chat/Batch Name/chat`), {
          
          text: message,

          userid:ourid,

          type: "text",
          //  user: ourid == userid ? "you" : senderName,
          timestamp:serverTimestamp( ),
        });

        // console.log(users_list);

        // users_list?.data.forEach(async (user) => {
        //     try {
        //         console.log(user)
        //         // Create a document for each user in the collection
        //         await addDoc(collection(db, 'Batch Chat/Batch Name/Studentdetail'), {
        //             email: user.email, // Replace 'user.email' with the actual key for email in the API data
        //             StudentName: user.StudentName, // Replace 'user.StudentName' with the actual key for StudentName in the API data
        //             uid: user.uid, // Replace 'user.uid' with the actual key for uid in the API data
        //             timestamp: serverTimestamp(),
        //         });
        //         // console.log('Document written with ID: ', docRef.id);
        //     } catch (error) {
        //         console.error('Error adding document: ', error);
        //     }
        // });
// console.log(user)
        const userDocRef = doc(db, "Batch Chat/Batch Name"); // Replace 'userid' with the recipient's user ID
        await setDoc(
          userDocRef,
          {
            active:  `${users_list.active}`,
            batchname:"student",
            subject:"science",
            timestamp: serverTimestamp(), // Store the entire message data
          },
          
        );

       
      } else if (messageType === "file") {
        // Send a file
        if (!file) {
          console.error("No file selected");
          return;
        }

        const storage = getStorage();
        const storageRef = ref(storage, "images/" + file.name);
        const uploadTask = uploadBytes(storageRef, file);

        let downloadURL;
        try {
          const snapshot = await uploadTask;
          downloadURL = await getDownloadURL(snapshot.ref);
        } catch (error) {
          console.error(error);
   
          return;
        }

        console.log(downloadURL);
        // Add the download URL to Firestore
        await addDoc(collection(db, `testnyyesss/Batchname/chat`), {
          file: downloadURL,
           // Store the download URL, not the file itself
          type: "file",

          userid:ourid,

         timestamp: serverTimestamp( ),
        });
    }


      setMessage("");
      setFile(null);
      setMessageType("text"); // Reset messageType to "text" after sending
    } catch (error) {
      console.error("Error sending message", error);
    }
  };

  useEffect(() => {
    const chatRef = collection(db, `Batch Chat/Batch Name/chat`);
    const q = query(chatRef, orderBy('timestamp'));
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newMessages = [];
      querySnapshot.forEach((doc) => {
        newMessages.push(doc.data());
      });
      setMessages(newMessages);
    });
  
    return () => unsubscribe();
  }, []);
  return (
    <div>
    <div className="container-fluid">
    <div className="container">
    <div className="row">
      <div className="chat-container clintchat">
  

{/* <Groupinfo/> */}
      
       
          <div className="chat-open">
            <div className="chat-messages">
            {messages.map((msg, index) => (
  <div
    key={index}
    className={`message ${msg.userid === ourid ? 'message-right' : 'message-left'}`}
    >
    {/* Render message content here */}
    <span className="username">
      {msg.userid === ourid ? 'You' : 'Other User'}
    </span>
    {msg.type === 'text' ? (
      <p className="text-message">{msg.text}</p>
    ) : (
      <div className="imagesent">
      {file.map((image, index) => (
          
        <a key={index} href={image} target="_blank" rel="noopener noreferrer">
      <img
        src={image}
        alt={`Selected ${index + 1}`}
        style={{
          width: "200px",
          height: "auto",
          margin: "10px",
          cursor: "pointer", // Optional: Change cursor to indicate it's clickable
        }}
      />
    </a>
          /* <img key={index} src={image} alt={`Selected ${index + 1}`} style={{ width: '300px', height: 'auto',margin:"10px"}} /> */
        ))}
        {/* <img src={msg.fileUrl} alt="File" width="200" /> */}
      </div>
    )}
  </div>
))}

            </div>
            <div className="messagetext clintchat1">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
                <label htmlFor="fileInput" className="fileInputLabel2">
                        <i class="fa-solid fa-paperclip"></i>
                  
                        <input
                       type="file"
        id="fileInput"
        name="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple 
                      />
                          </label>
              <div className="sending1">
              <button onClick={handleSendMessage}>Reply 
              {/* <ul>
      {listdata.map(user => (
        <li key={user.id}>
          <p>Name: {user.name}</p>
          <p>Email: {user.email}</p> */}
          {/* Render other user details as needed */}
        {/* </li>
      ))}
    </ul> */}
    </button>

              </div>
            </div>
          </div>
    
      
      </div>
    </div>
    </div>
    </div>
    </div>
  );
}

export default Chat;
