import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBtxdmhYim4ep4WtoSfvb1_MDVTbfz9_u0",
  authDomain: "imageset-1dcac.firebaseapp.com",
  projectId: "imageset-1dcac",
  storageBucket: "imageset-1dcac.appspot.com",
  messagingSenderId: "430166754907",
  appId: "1:430166754907:web:5fc5aeb543c0369586a17e",
  measurementId: "G-4SV857LFF0"
};

const app = initializeApp(firebaseConfig);


const db = getFirestore(app);
// const analytics = getAnalytics(app);

export {db}






// new firebase for realtime andfirestore start




//  dummyproject


// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getDatabase } from "firebase/database";
// // dev hob firebase
// const firebaseConfig= {
//   apiKey: "AIzaSyBtxdmhYim4ep4WtoSfvb1_MDVTbfz9_u0",
//   authDomain: "imageset-1dcac.firebaseapp.com",
//   projectId: "imageset-1dcac",
//   storageBucket: "imageset-1dcac.appspot.com",
//   messagingSenderId: "430166754907",
//   appId: "1:430166754907:web:5fc5aeb543c0369586a17e",
//   measurementId: "G-4SV857LFF0"
// };
// const firebaseConfig = {
//     apiKey: "AIzaSyDeCIFsV33S4q4_YrTlti6yyG4dQS36Evo",
//     authDomain: "devhub-tech.firebaseapp.com",
//     databaseURL: "https://devhub-tech-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "devhub-tech",
//     storageBucket: "devhub-tech.appspot.com",
//     messagingSenderId: "46753842955",
//     appId: "1:46753842955:web:69a06b9b3d83b26fd5440e",
//     measurementId: "G-PBXL1NZ1RQ"
// };
// Initialize Firebase app
// const app = initializeApp(firebaseConfig);

// Firestore initialization
// const db = getFirestore(app);

// Realtime Database initialization
// const dbRealtime = getDatabase(app);

// export { db, dbRealtime };


// dummyproject end


