import { useEffect, useState } from "react";
import {
  getStorage,
  ref as ref2,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../Firebase";
import { PaperClipOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import Chatbachinfo from "./Chatbachinfo";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";

const Batchinfo = () => {
  const { id } = useParams();
  const [batchDetails, setBatchDetails] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const profile = useSelector((state) =>
  state?.allapi?.profile ? state?.allapi?.profile : {}
);
useEffect(()=>{
  dispatch(allapiAction.profile()); 
},[])
  const currentUser = {
    id: profile.id,
    role: 'admin', // or 'member'
  };
  
  const isAdmin = currentUser.role === 'admin';
console.log(batchDetails)
  useEffect(() => {
    fetchData();
  }, []);
  


  const fetchData = async () => {
    try {
      const docRef = doc(db, 'Batch Chat', id);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const batchData = docSnap.data();
        setBatchDetails({
          name: batchData.batchname, // Assuming the batch name is stored in the 'name' field
          image: batchData.batchicon, // You can include other properties if needed
        });
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  
  console.log(id);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);
  const [messageType, setMessageType] = useState("text");
  const ourid = profile.id;
  // const currentUser = {
  //   id: 'your-user-id',
  //   role: 'admin', // or 'member'
  // };

const handleFileChange = (event) => {
    const selectedFile = event.target.files;
    const fileArray = [];
    setFile(fileArray);
    for (let i = 0; i < selectedFile.length; i++) {
    fileArray.push((selectedFile[i]));
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    
    if (fileArray.length > 0) {
      reader.readAsDataURL(fileArray[0]);
    }
    setMessageType("file"); // Set the messageType to "file" when a file is selected
  };

  
  const handleSendMessage = async () => {

    console.log("dfdfgfdg");
    if (message.trim() === "" && !file) return;
  console.log(file)
    try {
      if (messageType === "text") {
        await addDoc(collection(db, `Batch Chat/${id}/chat`), {
          text: message,
          // userid: ourid,
          userid: currentUser.id,
          role: isAdmin ? 'admin' : 'member',
          type: "text",
          timestamp: serverTimestamp(),
        });
      } else if (messageType === "file") {
        if (!file) {
          console.error("No file selected");
          return;
        }
  
        const storage = getStorage();
        const storageRef = ref2(storage, "images/" + file[0].name);
        const uploadTask = uploadBytes(storageRef, file[0]);
  
        try {
          await uploadTask; // Wait for the upload to complete
          const downloadURL = await getDownloadURL(storageRef); // Use the same reference
  
          await addDoc(collection(db, `Batch Chat/${id}/chat`), {
            file: downloadURL,
            type: "file",
            // userid: ourid,
            userid: currentUser.id,
            role: isAdmin ? 'admin' : 'member',
            timestamp: serverTimestamp(),
          });
        } catch (error) {
          console.error("Error uploading file", error);
          return;
        }
      }
      setMessage("");
      setFile(null);
      setMessageType("text");
      setImagePreview(null);
    } catch (error) {
      console.error("Error sending message", error);
    }
  };
  

  useEffect(() => {
    const chatRef = collection(db, `Batch Chat/${id}/chat`);
    const q = query(chatRef, orderBy('timestamp'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newMessages = [];
      querySnapshot.forEach((doc) => {
        newMessages.push(doc.data());
      });
      setMessages(newMessages);
    });

    return () => unsubscribe();
  }, []);


const[member,setMember]=useState([]);

  useEffect(() => {
      getDataFire();
    }, []);
    // const getDataFire = async () => {
    //   const querySnapshot = await getDocs(collection(db,`Batch Chat/${id}/Studentdetail`));
    //   const memberDataArray = querySnapshot.docs.map(doc => doc.data());
    //   console.log(memberDataArray);
    //   setMember(memberDataArray)
    // };
    const getDataFire = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, `Batch Chat/${id}/Studentdetail`));
        const memberDataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log(memberDataArray);
        setMember(memberDataArray);
      } catch (error) {
        console.error("Error fetching member data: ", error);
      }
    };
    
    
const getImageUrl = (path) => {
  return `${URL.API_BASE_URL}${path}`;
};
 
  return (
    <div>
    <div className="mychathbatch">
    <div className="scrollmember" >
    <ul>
    <li className="chatbatchname"> 
      <h6><img src={batchDetails.image} style={{width: "38%",
    height:"30px", borderRadius:"2em",marginRight:"4px"}} />{batchDetails.name}</h6>
      </li>
      {/* <li><Chatbachinfo  batchid={id} /></li> */}
      <br></br>
          {/* <li className="chatbatchname1">Members : </li> */}
          <li className="chatbatchname2">{member.map((memberItem, index) => (
            <li className="chatbatchname3" key={index}><img src=""></img>{memberItem.name}</li>
          ))}
          </li>
    </ul>
    </div>
      </div>


      <div className="container-fluid">
        <div className="container">
          <div className="row">
       
            <div className="chat-container clintchat">
              <div className="chat-open">
                <div className="chat-messages">
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`message ${
                        msg.userid === ourid ? 'message-right' : 'message-left'
                      }`}
                    >
                                      <span className="username">
  {/* Display the member's name if the message is not from the current user */}
  {msg.userid === currentUser.id
    ? <><img src={getImageUrl(profile.profile_image)} style={{ width: "20px", height: "20px", borderRadius: "50%", marginRight: "3px" }} />You</>
    : isAdmin
    ? 'You'
    : member.find((m) => m.id === msg.userid)?.name || 'Member'}
</span>
                      {msg.type === 'text' ? (
                        <p className="text-message">{msg.text}</p>
                      ) : msg.type === 'file' ? (
                        <div className="imagesent">
                          <a
                            href={msg.file}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={msg.file}
                              alt={`Selected File`}
                              style={{
                                width: "124px",
                                height: "auto",
                                margin: "10px",
                                cursor: "pointer",
                              }}
                            />
                            <br />
                            <span className="timeimg">
                              <p>{msg.timelocal}</p>
                            </span>
                          </a>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="messagetext">
                {/* <span className="imgpreview">{imagePreview && (
          <img
            src={imagePreview}
            alt="Image Preview"
            style={{ width: "100px", height: "100px", marginRight: "10px" }}
          />
        )}</span> */}
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <label htmlFor="fileInput" className="fileInputLabel2">
                  <span className="imgpreview">{imagePreview && (
          <img
            src={imagePreview}
            alt="Image Preview"
            style={{ width: "100px", height: "100px", marginRight: "10px" }}
          />
        )}</span><PaperClipOutlined />
                    <input
                      type="file"
                      id="fileInput"
                      name="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      multiple
                    />
                  </label>
                  <div className="sending1">
                    <button onClick={handleSendMessage}>Reply</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Batchinfo;
