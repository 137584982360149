import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { allapiAction } from '../../Redux/common/action';
import { Card, Col, Row } from 'antd';
import Chatbachinfo from './Chatbachinfo';
import { URL } from '../../Redux/common/url';

const Batchs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  // Assuming batch_list is an array
  const batch_list = useSelector((state) =>
    state?.allapi?.batch_list ? state?.allapi?.batch_list : []
  );
  const batchlistdata = batch_list?.data ? batch_list?.data : [];
  useEffect(() => {
    // Fetch or dispatch an action to get batch_list data
    dispatch(allapiAction.batch_list());
  }, []);

  const getImageUrl = (path) => {
    return `${URL.API_BASE_URL}${path}`;
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
//     <div>
//       <h1>Batch List</h1>
//       <ul>
//         {batchlistdata.map((batch) => (
//           <li key={batch.id}>{batch.name}</li>
//           // Replace 'id' and 'name' with the actual properties of your batch object
//         ))}
//       </ul>
//     </div>
//   );
// };


<div>
<div className="creategroupposition">
  {/* <Createbatch /> */}
</div>
<div className="chatinfocard">
  <Row gutter={16}>
  {batchlistdata.map((data) => (
      <Col span={6}>
        <Card
          title={
            <div className="setinfoposition">
              <Row gutter={16}>
                <Col span={8}>
               
                  <img
      src={getImageUrl(data.image)}
      alt={data.image}
      style={{ width: '50px', height: '50px', borderRadius: '50%', marginTop: '1px' }}
    />
                </Col>
                <Col className="perastyle" span={8}>
                  <Row gutter={16}>
                    <p>
                      <Link to={`/batchinfo/${data.id}`}>
                        {data.name}
                      </Link>
                    </p>
                    <Row gutter={16}>
                      <Col span={8}>
                        <p>
                          <Chatbachinfo batchid={data.id} />
                        </p>
                        {/* <p><Link to={`/batchmembers/${data.id}`}><img src="images/users5.png" /></Link></p> */}
                      </Col>
                      <Col span={8}>
                        <p>
                          <Link to={`/batchinfo/${data.id}`}>
                            <img src="images/message.png" />
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </div>
          }
          bordered={false}
        >
         </Card>
      </Col>
    ))}
  </Row>
</div>
</div>
);
};
export default Batchs;
