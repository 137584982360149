import {
  LOADING,
  FAQS_LIST,
  USERS_LIST,
  USERS_CONTACT_LIST,
  CATEGORY_LIST,
  QUIZ_LIST,
  QUESTION_LIST,
  DESHBOARD,
  POSTLIKE,
  ALLPOST,
  SUBJECT_CATEGORY_LIST,
  SUBJECT_LIST,
  BATCH_LIST,
  BATCH_DETAIL,
  ADD_SUBJECTS,
  ADD_SUBJECTSLIST,
  POSTLIST,
  COMMENTSLIST,
  PROFILE,
  SUTUDENT_LIST,
  ADD_SUTUDENT,
  STUDENT_LIST,
  STUDY_MATERIALADD,
  STUDY_MATERIALLIST,
  STUDY_MATERIALADDBATCH,
  STUDY_MATERIALSPGET,
  ALLSTUDY_MATERIAL,
  // COMMENTS,
  // COMMENTSLIST,
} from "../common/constant";






const initialState = {
  loading: false,

  categories_delete: {},
  users_list: {},
  users_contact_list: {},
  category_list: {},
  quiz_list: {},
  question_list: {},
  deshboard: {},
  postLike: {},
  allpost: {},
  subject_category_list:{},
  subject_list:{},
  batch_list:{},
  batch_detail:{},
  add_subjects:{},
  subjects_by_category:{},
  student_list:{},
  add_students:{},
  add_studymaterial:{},
  study_materiallist:[],
  add_studymaterialBatch:{},
  material_by_subject:[],
  allmaterialList:{},
  faqs_list: [],
  // createPost:{},
  // post_list:[],
  all_comments:[],
  profile:{},
  getuserList:{},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case FAQS_LIST:
      return { ...state, faqs_list: action.payload };
    case USERS_LIST:
      return { ...state, users_list: action.payload };
    case USERS_CONTACT_LIST:
      return { ...state, users_contact_list: action.payload };
    case CATEGORY_LIST:
      return { ...state, category_list: action.payload };
    case QUIZ_LIST:
      return { ...state, quiz_list: action.payload };
    case QUESTION_LIST:
      return { ...state, question_list: action.payload };
    case DESHBOARD:
      return { ...state, deshboard: action.payload };
    case POSTLIKE:
      return { ...state, postLike: action.payload };
    case ALLPOST:
      return { ...state, allpost: action.payload };
    // case OTP_BOX_FORGOT:
    //   return { ...state, serach_pro_pub: action.payload };
    case SUBJECT_CATEGORY_LIST:
      return { ...state, subject_category_list: action.payload };

     case SUBJECT_LIST:
      return { ...state, subject_list: action.payload };

     case BATCH_LIST:
        return { ...state, batch_list: action.payload };
       
     case BATCH_DETAIL:
          return { ...state, batch_detail: action.payload };

      case ADD_SUBJECTS:
        return { ...state, add_subjects: action.payload };

        case ADD_SUBJECTSLIST:
          return { ...state, subjects_by_category: action.payload };
        
      case COMMENTSLIST:
        return { ...state, all_comments: action.payload };

      case PROFILE:
          return { ...state, profile: action.payload };

      case ADD_SUTUDENT:
        return { ...state, add_students: action.payload };

      case STUDENT_LIST:
          return { ...state, student_list: action.payload };

     case STUDY_MATERIALADD:
            return { ...state, add_studymaterial: action.payload };

     case STUDY_MATERIALLIST:
              return { ...state, study_materiallist: action.payload };
         
      case STUDY_MATERIALADDBATCH:
                return { ...state, add_studymaterialBatch: action.payload };

      case STUDY_MATERIALSPGET:
              return { ...state, material_by_subject: action.payload };  
         
      case ALLSTUDY_MATERIAL:
                return { ...state, allmaterialList: action.payload };          
        // case POSTLIST:
        //   return { ...state, post_list: action.payload };

    default:
      return state;
  }
};
