
// Userlist.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { allapiAction } from '../../Redux/common/action';
import { db } from '../Firebase'; // Import your Firebase configuration file
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';

const Userlist = () => {
  const [batchDetails, setBatchDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || 1;
  const search = searchParams.get('search') || '';

  console.log(searchParams);
  console.log(page);
  console.log(search);

  const usersList = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );

  const listData = usersList?.data ? usersList?.data : [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (search) {
          await dispatch(allapiAction.getuserListSearch({ page, search }));
        } else {
          await dispatch(allapiAction.getuserList(page));
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();

    // Cleanup function (if needed)
    return () => {
      // Any cleanup logic here
    };
  }, [dispatch, page, search, location]);

  // Store data in Firestore
// Store data in Firestore
const storeDataInFirestore = async (user) => {
  try {
    console.log("Storing data in Firestore:", user);
    const userRef = collection(db, "members");
    const querySnapshot = await getDocs(query(userRef, where("id", "==", user.id)));

    if (querySnapshot.empty) {
      console.log("Adding new user to Firestore:", user);
      const docRef = await addDoc(userRef, user);
      console.log("Document written with ID:", docRef.id);
    } else {
      console.log("User with ID", user.id, "already exists in Firestore. Skipping.");
    }
  } catch (error) {
    console.error('Error storing data in Firestore:', error);
  }
};

  
  useEffect(() => {
    listData.forEach((user) => {
      storeDataInFirestore(user);
    });
  }, [listData]);
  // useEffect(() => {
  //   // Check if listData is not empty to avoid unnecessary Firestore calls
  //   if (listData.length > 0) {
  //     listData.forEach((user) => {
  //       storeDataInFirestore(user);
  //     });
  //   }
  // }, []);
  return (
  <div>
    {listData.map((user) => (
      <div key={user.id}>
        <h2>{user.name}</h2>
        <p>Email: {user.email}</p>
        {/* Add other user information as needed */}
      </div>
    ))}
  </div>
);

};

export default Userlist;
