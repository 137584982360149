import React from "react";
import User from "../Component/User/User";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../Component/Home/Home";
import Login from "../Component/Login/Login";
import LayoutMain from "../Component/Layout";
import { GetToken } from "../Component/utils";
import Contact from "../Component/User/Contact";
import Category from "../Component/Category/Category";
import Quiz from "../Component/Quiz/Quiz";
import Question from "../Component/Question/Question";
import Post from "../Component/post/Post";
import Chat from "../Component/chat/Chat";
import Batchs from "../Component/chat/Batchs";
import Batchinfo from "../Component/chat/Batchinfo";
import Batchmembers from "../Component/chat/Batchmembers";
import Userlist from "../Component/chat/Userlist";
import Subjectcategory from "../Component/SubjectCategory/Subjectcategory";
import Subject from "../Component/SubjectCategory/Subject";
import Batch from "../Component/Batch/Batch";
import Profile from "../Component/Login/Profile";
import Studymaterial from "../Component/Subjectbycategory/Studymaterial";
import BatchStudymaterial from "../Component/Batch/BatchStudymaterial";


function PageRouter() {
  function RequireAuth({ children }) {
    const token = GetToken();
    return token ? children : <Navigate to="/login" replace />;
  }
  function AlreadyLogin({ children }) {
    const token = GetToken();
    return token ? <Navigate to="/" replace /> : children;
  }
  return (
    <div>
      <Routes>
        {/* <Route path="/forgot-password" element={<AlreadyLogin> <ForgotPassword /></AlreadyLogin>} /> */}
        <Route
          path="/login"
          element={
            <AlreadyLogin>
              <Login />
            </AlreadyLogin>
          }
        />
        <Route
          element={
            <RequireAuth>
              {" "}
              <LayoutMain />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<User />} />
          <Route path="/users/contact/:id" element={<Contact />} />
          <Route path="/category" element={<Category />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/post" element={<Post />} />
          <Route path="/question" element={<Question />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/batch" element={<Batchs />} />
          <Route path="/batchmembers/:id" element={<Batchmembers />} />
          <Route path="/batchinfo/:id" element={<Batchinfo />}/> 
          <Route path="/userlist" element={<Userlist />} />
          <Route path="/subjectcategory" element={<Subjectcategory />} />
          <Route path="/subject" element={<Subject />} />p
          <Route path="/batchcreate" element={<Batch />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/studymaterial" element={<Studymaterial />} />
          <Route path="/batchstudymaterial/:id" element={<BatchStudymaterial />} />

          {/* <Route path="/faq-category" element={<FaqCategory />} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default PageRouter;
