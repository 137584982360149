

const setToken = (access_token) => {


  
  localStorage.setItem("access_token", access_token);

  return true;
};

const getToken = () => {
  const token = localStorage.getItem("access_token");

  if (token) {
    return token;
  } else {
    return false;
  }
};

const setUserDetail = (access_user) => {
  localStorage.setItem("access_user", access_user);

  return true;
};

const getUserDetail = () => {
  const userDeatail = localStorage.getItem("access_user");

  if (userDeatail) {
    return userDeatail;
  } else {
    return false;
  }
};

module.exports = {
  setToken,
  getToken,
  setUserDetail,
  getUserDetail,


};
