import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input, Select } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { BookOutlined, ExclamationCircleFilled, UsergroupAddOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";
import { URL } from "../../Redux/common/url";
import { addDoc, collection, collectionGroup, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

function Batch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const [selectedSubject, setSelectedSubject] = useState();

  const batch_list = useSelector((state) =>
  state?.allapi?.batch_list ? state?.allapi?.batch_list : {}
);

// const add_studymaterialBatch = useSelector((state) =>
// state?.allapi?.add_studymaterialBatch ? state?.allapi?.add_studymaterialBatch
// : {}
// );
// const listdatasmat = add_studymaterialBatch?.data ? add_studymaterialBatch?.data : [];
  // console.log(batch_list);

  const listdata = batch_list?.data ? batch_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = (e) => {
    // console.log(e);
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.delete_batch({id: e, page: page }));
    dispatch(allapiAction.batch_list({page:page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    const previewUrl = window.URL.createObjectURL(event.target.files[0]);

  setImagePreview(previewUrl);
  };


  const showModal = (e) => {
    // console.log(e);
    setcateDetail(e);
    setsubDetail(e);
 console.log(e);
    dispatch(allapiAction.subject_category_list(1));
    // setSelectedSubjects1(e.category.name)
    setIsModalOpen(true);
  };



  // const [selectedFile1, setSelectedFile1] = useState(null);

  // const handleFileSelect1 = (event) => {
  //   setSelectedFile1(event.target.files[0]);
  // };
  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail((prevData) => ({ ...prevData, [name]: value }));
    console.log("Updated cateDetail:", cateDetail);
  };
  // const handleChangesubject = (selectedValue) => {
  //   setSelectedSubjects1(selectedValue);
  // };
  const handleChangestudent = (selectedValues) => {
    setSelectedStudents(selectedValues);
    console.log('Updated selectedStudents:', selectedValues);
  };
  
    const handleChangesub = (selectedValue) => {
      setSelectedSubjects(selectedValue);
    };
  
  const handleOk = () => {
    const data = new FormData();
  
    if (!cateDetail?.id) {
      toast.error("Invalid category ID.");
    } else {
     
      data.append("id", cateDetail?.id);
  
        if (cateDetail?.name) {
        data.append("name", cateDetail?.name);
      }
      // if (selectedSubjects1) {
      //   data.append("category_id", selectedSubjects1);
      // }
   
      if (selectedFile) {
        data.append("image", selectedFile);
      }

      dispatch(allapiAction.update_batch(data));

      setIsModalOpen(false);

      dispatch(allapiAction.batch_list({ page: page }));
    }
    // setSelectedSubjects1("")
  };
  
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [batchdata,setbatchdata] = useState();
  const [cateDetailAdd, setcateDetailAdd] = useState({});
// console.log(cateDetailAdd)
  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
    setbatchdata({ ...cateDetailAdd, [name]: value });
  };
 
  

  const showModalAdd = (e) => {
    // console.log(e);
    setcateDetail(e);
    setsubDetail(e);
    dispatch(allapiAction.subject_category_list(1));

    setAddModalOpen(true);
  };
  const handleOkAdd = async() => {
    if (!cateDetailAdd?.name) {
      toast.error("please enter name");
    } else {
      console.log("subDetail")
      const data = new FormData();
      data.append("page", 1);
      data.append("category_id", selectedSubjects);
      // data.append("course_id", 5);
      // data.append("subject_id", 1);
      data.append("name", cateDetailAdd?.name);
      data.append("image", selectedFile);

     const response = await dispatch(allapiAction.create_batch(data))
    //  const response1 = await dispatch(allapiAction.study_materiallist(data))selectedSubject
  

     console.log(response);
      // const batchChatCollection = collection(db, `BatchChat/${id}/messages`)
      const firestoreDocumentId = response?.data?.id;

    // Now, store the data in Firestore with the generated document ID
    const storage = getStorage();
    const storageRef = ref(storage, "images/" + selectedFile.name);
    const uploadTask = uploadBytes(storageRef, selectedFile);

    let downloadURL;
    try {
      const snapshot = await uploadTask;
      downloadURL = await getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error(error);

      return;
    }

    console.log(downloadURL);
    const userDocRef = doc(db, `Batch Chat/${firestoreDocumentId}`); // Replace 'userid' with the recipient's user ID
    await setDoc(
      userDocRef,
      {
    // const docRef = await addDoc(collection(db, `Batch Chat/${firestoreDocumentId}/`), {
      active: "Live",
      subject: selectedSubjects,
      batchname: cateDetailAdd?.name,
      batchicon:downloadURL,
      timestamp: serverTimestamp(),
     });
      
     console.log("Document written with ID: ", firestoreDocumentId);
      
  
      setcateDetailAdd({  });
      setSelectedFile(null);
      // dispatch(adminAction.getRoleList('dhsg'))
      setAddModalOpen(false);
      dispatch(allapiAction.batch_list({page:page}));
      return () => {};
    }

};
// firebase
// ...

// const handleOkAdddoc = async () => {
//   try {
//     console.log("test")
//     const data = {
//       // Add your data fields here
//       name: cateDetailAdd.name,
//       category_id: selectedSubjects,
//       // Add other fields as needed
//       createdAt: serverTimestamp(), // Use server timestamp for createdAt field
//     };

//     const docRef = await addDoc(collection(db, "BatchChat"), data);

//     console.log("Document written with ID: ", docRef.id);

//     // Perform any additional actions or dispatch Redux actions as needed

//     // Close the modal or perform other actions
//     setAddModalOpen(false);

//     // Refresh batch list
//     dispatch(allapiAction.batch_list({ page: page }));
//   } catch (error) {
//     console.error("Error adding document: ", error);
//     // Handle error, show toast, or dispatch an error action as needed
//     toast.error("Error adding document to Firestore");
//   }
// };



// ...
const [isModalOpensub, setIsModalOpensub] = useState(false);
const showModalsub = (e) => {
  console.log(e);
  setsubDetail(e);
  setIsModalOpensub(true);
};
const handleCancel3 = () => {
  setIsModalOpen(false);
  setIsModalOpensub(false);
};


  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const getImageUrl = (path) => {
    return `${URL.API_BASE_URL}${path}`;
  };


  const showModalstudent = (e) => {
    console.log(e);
    setsubDetail(e);
    // setSelectedStudents(e);
    // setIsModalOpenstudent(true);
    setIsModalOpenstudent(e);
    // setStudentDetail(e);
  };
// console.log(URL)
  const columns = [
  
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Batch Name",
      dataIndex: "image",
      key: "image",
      render: (image, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={getImageUrl(image)} // Assuming that the image property is the path or URL
            alt="Artical Image"
            style={{ width: "40px", height: "40px", objectFit: "cover", marginRight: "8px" , marginTop:"0px" , borderRadius: '30%' }}
          />
          <p>{record.name}</p>
        </div>
      ),
    },
    {
      title: "Category Name",
      dataIndex:  ["category", "name"], 
      key: "category",
    },
    // {
    //   title: "StudyMaterial",
    //   dataIndex:  ["studyMaterial", "name"], 
    //   key: "studyMaterial",
    // },
  
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
           <Tooltip title="Subject">
              {/* <span
                className="cursor-pointer"
                onClick={() => {
                  showModalsub(item?.id);
                }}
              > */}
              <Link to={`/batchstudymaterial/${item.id}`}>
              <BookOutlined className="scale-icon edit-icon" />
                      </Link>
                
              {/* </span> */}
            </Tooltip>
              <Tooltip title="Student">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModalstudent(item?.id);
                }}
              >
                <UsergroupAddOutlined className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.batch_list(1));

    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");

  const handleChange = (e) => {
    // console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.batch_listSearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.batch_list(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.batch_listSearch({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.batch_list(e));

      return () => {};
    }
  };
// updated
const[stuDetail,setStudetail]= useState({})
  const [ subDetail, setsubDetail] = useState({});
  // console.log(subDetail);
  const subject_category_list = useSelector((state) =>
  state?.allapi?.subject_category_list ? state?.allapi?.subject_category_list : {}
);

  // console.log(subject_category_list);

  const subcategorylistdata = subject_category_list?.data ? subject_category_list?.data : [];
  // useEffect(() => {
  //   dispatch(allapiAction.subject_category_list(1));

  //   return () => {};
  // }, []);
  
  const options = subcategorylistdata.map((user) => ({
    label: user.name,
    value: user.id,
    // email: user.email, // Add the email property if it exists in your user object
    // Add other properties as needed
  }));
  const [selectedSubjects, setSelectedSubjects] = useState();
 
          
  // create batch ka use krna h

    // for subject
    
// const subjects_by_category = useSelector((state) =>
// state?.allapi?.subjects_by_category ? state?.allapi?.subjects_by_category : {}
// );

// console.log(subjects_by_category);

// const subjectlistdata = subjects_by_category?.data ? subjects_by_category?.data : [];
// useEffect(() => {
//   dispatch(allapiAction.subjects_by_category(1));

//   return () => {};
// }, []);

// const options2 = subjectlistdata.map((user) => ({
//   label: user.name,
//   value: user.id,
// }));
// close
  
    // console.log(subDetail);

    // for student add
    const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
    );
    
    const listData = users_list?.data ? users_list?.data : [];
    useEffect(() => {
      dispatch(allapiAction.users_list(1));
  
      return () => {};
    }, []);

    const [selectedStudents, setSelectedStudents] = useState([]);
      console.log(selectedStudents)
      const options1 = listData.map((user) => ({
        label: user.name,
        value: user.id,
        email: user.email,
        key: user.id, // Add a unique key for each option
        selected: selectedStudents.includes(user.id), // Check if the user is already selected
      }));
      const [isModalOpen1, setIsModalOpen1] = useState(false);
      const showModal1 = () => {
        setIsModalOpen1(true);
      };
    
      const handleCancel1 = () => {
        setIsModalOpens(false);
        setIsModalOpenstudent(false);
      };
    console.log(batch_list)
    const [isModalOpenstudent, setIsModalOpenstudent] = useState(false);
    
   const add_students = useSelector((state) =>
   state?.allapi?.add_students ?  state?.allapi?.add_students : {}
   );
   
   console.log(add_students)


  console.log(selectedStudents);

  // const handleChangestudent = (selectedValues) => {
  //   setSelectedStudents([...new Set([...selectedStudents, ...selectedValues])]);
  // };
  
  const [isModalOpens, setIsModalOpens] = useState(false);

 
    const handleOkAddStudents = async (e) => {
      e?.preventDefault();
      console.log('submitted');
    
      let data = new FormData();
      data.append("batch_id", subDetail);
      // data.append("batch_id", e.id);
    
      // Iterate over selectedSubjects array and append each subject to FormData
      selectedStudents.forEach((students, index) => {
        data.append(`student_id[${index}]`, students);
      });
      setIsModalOpens(false);
      // redux
      dispatch(allapiAction.add_students(data));
    
      console.log(data);
    }

    // const handleOkmaterial = async (e) => {
    //   e?.preventDefault();
    //   console.log('submitted');
    
    //   let data = new FormData();
    //   data.append("page", 1);
    //   data.append("course_id", subDetail);
    //   data.append("subject_id", selectedSubject);
    
    //   // Iterate over selectedSubjects array and append each subject to FormData
    //   // selectedSubject.forEach((subject, index) => {
    //   //   data.append(`subjects[${index}]`, subject);
    //   // });
    
    //   // redux
    //   // dispatch(allapiAction.add_study_material_to_batch(data));
    //   dispatch(allapiAction.add_studymaterial(data));
    
    //   console.log(data);
    // }
// updatebatch
 // const [selectedSubjects1, setSelectedSubjects1] = useState();

  return (
    <div>
    <div className="batchfield">
    <div className="modalselectbtn">
      <Modal
        title="Add Subject Category"
        open={isModalOpensub}
        // onOk={handleOkmaterial}
        onCancel={handleCancel3}
      >
  <Col lg={24} md={24} sm={24} xs={24}>
  {/* <Select 
          id="subject"
          mode="single"
          style={{ width: '100%' }}
          placeholder="Select Subject "
          defaultValue={[]}
          value={selectedSubject}
          onChange={handleChangesubject}
          optionLabelProp="label"
          options={options2}
          optionRender={(option) => (
            <Space>
              {option.label}
            </Space>
          )}
        /> */}
        </Col>
      </Modal>
      </div>
    {/* close */}
{/* student */}
<div className="modalselectbtn">
      <Modal
        title="Add Students to Batch"
        open={isModalOpenstudent}
        onOk={handleOkAddStudents}
        onCancel={handleCancel1}
      >
  <Col lg={24} md={24} sm={24} xs={24}>
  <Select
          id="students"
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Select Subject"
          defaultValue={[]}
          value={selectedStudents}
          onChange={handleChangestudent}
          optionLabelProp="label"
          options={options1}
          optionRender={(option) => (
            <Space>
              {option.label}
            </Space>
          )}
        />
        </Col>
        {/* <div className='uldesign'>
        <p style={{ fontWeight: '500' }}>Members:</p>
        <ul>
      {batchData.map((data, index) => (
        
        <li key={index} className="setinfoposition1">
          <img src={data.icon} style={{ width: '46px', borderRadius: '10px' }} />
          <p>{data.name}
          <span className='btnpositionset'><Button type="danger" onClick={() => handleDeleteUser(data.id)}>
            <img src="images/remove.png" 
className='remove-icon'/>
          </Button></span></p>
        </li>
         
      ))}
      </ul>
      </div> */}
      </Modal>
      </div>
      {/* student close */}
      {/* update */}
      <Modal
        title="Update Batch"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
        {/* <Select
          id="subjects"
          mode="single"
          style={{ width: '100%' }}
          placeholder="Select Course"
          defaultValue={[]}
          value={selectedSubjects1}
          onChange={handleChangesubject}
          optionLabelProp="label"
          options={options}
          optionRender={(option) => (
            <Space>
              {option.label}
            </Space>
          )}
        /> */}
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="Name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
         <input
  type="file"
  className="form-control"
  name="image"
  onChange={handleFileSelect}
/>

{selectedFile ? (
  <img
    src={window.URL.createObjectURL(selectedFile)}
    alt="Selected Image"
    style={{ width: '70px', height: '70px', borderRadius: '50%', marginTop: '10px' }}
  />
) : (
  !selectedFile && cateDetail?.image && (
    <img
      src={getImageUrl(cateDetail?.image)}
      alt={cateDetail?.image}
      style={{ width: '70px', height: '70px', borderRadius: '50%', marginTop: '10px' }}
    />
  )
)}


        </Col>
      </Modal>

      {/* create */}
      
      <Modal
        title="Create Batch"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
        <Select 
          id="subjects"
          mode="single"
          style={{ width: '100%' }}
          placeholder="Select Subject Category"
          defaultValue={[]}
          value={selectedSubjects}
          onChange={handleChangesub}
          optionLabelProp="label"
          options={options}
          optionRender={(option) => (
            <Space>
              {option.label}
            </Space>
          )}
        />
        {/* <Select 
          id="subject"
          mode="single"
          style={{ width: '100%' }}
          placeholder="Select Subject "
          defaultValue={[]}
          value={selectedSubject}
          onChange={handleChangesubject}
          optionLabelProp="label"
          options={options2}
          optionRender={(option) => (
            <Space>
              {option.label}
            </Space>
          )}
        /> */}
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="Name"
            name="name"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
          <input type ="file"
            // autoComplete="new-off"
            className="form-control"
            // placeholder="short name"
            name="image"
            // value={cateDetailAdd?.image}
            onChange={handleFileSelect}
          />
          <p>{imagePreview && (
  <img src={imagePreview} alt="Image Preview" style={{ width: '70px', height: '70px', borderRadius: '50%' ,marginTop:"10px",marginLeft:"10px"}} />
)}</p>
        </Col>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Batch"}
            subHeading={`Total ${batch_list?.total || 0} Batch`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total:batch_list?.total? batch_list?.total:0
          }}
        />
      </div>
    </div>
    </div>
  );
}

export default Batch;

